'use strict';

document.addEventListener('DOMContentLoaded', function(){

	// carousel slider
	const carousel = document.querySelector('.carousel--four');

	if(carousel) {
		const sliderCarousel = carousel.querySelector('.carousel__slider');

		var swiperCarousel = new Swiper(sliderCarousel, {
			loop: true,
			navigation: {
				nextEl: carousel.querySelector(".swiper-button-next"),
				prevEl: carousel.querySelector(".swiper-button-prev"),
			},
			breakpoints: {
				// when window width is >= 320px
				320: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
				// when window width is >= 768px
				1280: {
					slidesPerView: 4,
					spaceBetween: 20,
				}
			},
			on: {
				slideChange: function () {
					const currentIndex = this.activeIndex;
					clearIsShow();
					changeIsShow();
				},
			},
		});

		function clearIsShow() {
			const slidesCarousel = carousel.querySelectorAll('.carousel__slide');
	
			slidesCarousel.forEach(function(slide) {
				if(slide.classList.contains('is-show')) {
					slide.classList.remove('is-show');
				}

				if(slide.classList.contains('is-show-1')) {
					slide.classList.remove('is-show-1');
				}

				if(slide.classList.contains('is-show-2')) {
					slide.classList.remove('is-show-2');
				}

				if(slide.classList.contains('is-show-3')) {
					slide.classList.remove('is-show-3');
				}

				if(slide.classList.contains('is-show-4')) {
					slide.classList.remove('is-show-4');
				}
			})
		}

		function changeIsShow() {
			const slidesCarousel = carousel.querySelectorAll('.carousel__slide');
			const index = carousel.querySelector('.swiper').swiper.activeIndex;


			if (window.matchMedia("(min-width: 1280px)").matches) {
				slidesCarousel[index].classList.add('is-show', 'is-show-1');
				slidesCarousel[index+1].classList.add('is-show', 'is-show-2');
				slidesCarousel[index+2].classList.add('is-show', 'is-show-3');
				slidesCarousel[index+3].classList.add('is-show', 'is-show-4');
			} else {
				slidesCarousel[index].classList.add('is-show', 'is-show-1');
			}
		};

		changeIsShow();
	}

	// review slider
	const review = document.querySelector('.review');

	if(review) {
		const sliderReview = review.querySelector('.review__slider');

		var swiperReview = new Swiper(sliderReview, {
			loop: true,
			navigation: {
				nextEl: review.querySelector(".swiper-button-next"),
				prevEl: review.querySelector(".swiper-button-prev"),
			},
			breakpoints: {
				// when window width is >= 320px
				320: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
				// when window width is >= 1280px
				1280: {
					slidesPerView: 3,
					spaceBetween: 20,
				}
			},
			on: {
				slideChange: function () {
					const currentIndex = this.activeIndex;
					clearIsShow();
					changeIsShow();
				},
			},
		});

		function clearIsShow() {
			const slidesReview = review.querySelectorAll('.review__slide');
	
			slidesReview.forEach(function(slide) {
				if(slide.classList.contains('is-show')) {
					slide.classList.remove('is-show');
				}

				if(slide.classList.contains('is-show-1')) {
					slide.classList.remove('is-show-1');
				}

				if(slide.classList.contains('is-show-2')) {
					slide.classList.remove('is-show-2');
				}

				if(slide.classList.contains('is-show-3')) {
					slide.classList.remove('is-show-3');
				}
			})
		}

		function changeIsShow() {
			const slidesReview = review.querySelectorAll('.review__slide');
			const index = review.querySelector('.swiper').swiper.activeIndex;

			if (window.matchMedia("(min-width: 1280px)").matches) {
				slidesReview[index].classList.add('is-show', 'is-show-1');
				slidesReview[index+1].classList.add('is-show', 'is-show-2');
				slidesReview[index+2].classList.add('is-show', 'is-show-3');
			} else {
				slidesReview[index].classList.add('is-show', 'is-show-1');
			}
		};

		changeIsShow();
	}


	// video slider
	const videoSlider = document.querySelector('.video__slider'),
				videoSliderWrp = document.querySelector('.video__slider-box');

	if(videoSlider) {
		var swiperVideo = new Swiper(".video__slider", {
			breakpoints: {
				// when window width is >= 320px
				320: {
					slidesPerView: 1,
					spaceBetween: 10,
				},
				// when window width is >= 1280px
				768: {
					slidesPerView: 3,
					spaceBetween: 40,
				}
			},
			loop: true,
			navigation: {
				nextEl: videoSliderWrp.querySelector(".swiper-button-next"),
				prevEl: videoSliderWrp.querySelector(".swiper-button-prev"),
			},
			on: {
				slideChange: function () {
					const currentIndex = this.activeIndex;
					clearIsShow();
					changeIsShow();
				},
			},
		});

		function clearIsShow() {
			const videosProduct = videoSlider.querySelectorAll('.video__slide');
	
			videosProduct.forEach(function(slide) {
				if(slide.classList.contains('is-show')) {
					slide.classList.remove('is-show');
				}
			})
		}

		function changeIsShow() {
			const videosProduct = videoSlider.querySelectorAll('.video__slide');
			const index = videoSlider.swiper.activeIndex;

			videosProduct[index].classList.add('is-show');
		};

		changeIsShow();
	};

	// threeCarousel slider
	const threeCarousel = document.querySelector('.carousel--three');

	if(threeCarousel) {
		const threeSlider = threeCarousel.querySelector('.carousel__slider');

		var swiperCarousel = new Swiper(threeSlider, {
			loop: true,
			navigation: {
				nextEl: threeCarousel.querySelector(".swiper-button-next"),
				prevEl: threeCarousel.querySelector(".swiper-button-prev"),
			},
			breakpoints: {
				// when window width is >= 320px
				320: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
				// when window width is >= 768px
				1280: {
					slidesPerView: 3,
					spaceBetween: 20,
				}
			},
			on: {
				slideChange: function () {
					const currentIndex = this.activeIndex;
					clearIsShow();
					changeIsShow();
				},
			},
		});

		function clearIsShow() {
			const slidesThreeCarousel = threeCarousel.querySelectorAll('.carousel__slide');
	
			slidesThreeCarousel.forEach(function(slide) {
				if(slide.classList.contains('is-show')) {
					slide.classList.remove('is-show');
				}

				if(slide.classList.contains('is-show-1')) {
					slide.classList.remove('is-show-1');
				}

				if(slide.classList.contains('is-show-2')) {
					slide.classList.remove('is-show-2');
				}

				if(slide.classList.contains('is-show-3')) {
					slide.classList.remove('is-show-3');
				}
			})
		}

		function changeIsShow() {
			const slidesThreeCarousel = threeCarousel.querySelectorAll('.carousel__slide');
			const index = threeCarousel.querySelector('.swiper').swiper.activeIndex;


			if (window.matchMedia("(min-width: 1280px)").matches) {
				slidesThreeCarousel[index].classList.add('is-show', 'is-show-1');
				slidesThreeCarousel[index+1].classList.add('is-show', 'is-show-2');
				slidesThreeCarousel[index+2].classList.add('is-show', 'is-show-3');
			} else {
				slidesThreeCarousel[index].classList.add('is-show', 'is-show-1');
			}
		};

		changeIsShow();
	}

	// review slider
	const reviewList = document.querySelector('.review-page__list');

	if(reviewList) {
		const sliderReviewListBox = reviewList.querySelector('.review-list__slider');

		var sliderReviewList = new Swiper(sliderReviewListBox, {
			loop: true,
			slidesPerView: 1,
			spaceBetween: 0,
			pagination: {
				el: sliderReviewListBox.querySelector('.review-list__pagination'),
				clickable: true
			}
		});
	}
})